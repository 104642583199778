import React, { useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function TimeAnalysisChat({ historicalData }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const presetQuestions = [
    "What are my top time investments over the past year?",
    "How has my time allocation changed quarter over quarter?",
    "Which months show the highest productivity?",
    "What patterns do you notice in my unallocated time?",
    "How balanced is my time investment across different categories?"
  ];

  const handleSendMessage = async (content) => {
    if (!content.trim()) return;

    const userMessage = { id: Date.now(), content, role: 'user' };
    setMessages(prev => [...prev, userMessage]);
    setNewMessage('');
    setIsLoading(true);

    try {
      const response = await axios.post('/api/chat/time-analysis', {
        message: content,
        historicalData
      });

      setMessages(prev => [...prev, {
        id: Date.now() + 1,
        content: response.data.response,
        role: 'assistant'
      }]);
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="lg:w-1/3 w-full lg:pl-4 mt-8 lg:mt-0">
    <div className="border rounded-lg p-4 h-full">
        <h2 className="text-2xl font-bold mb-4">Time Analysis Chat</h2>
        
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Suggested Questions:</h3>
          <div className="flex flex-wrap gap-2">
            {presetQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => handleSendMessage(question)}
                className="bg-blue-100 hover:bg-blue-200 text-blue-800 px-3 py-1 rounded-full text-sm"
              >
                {question}
              </button>
            ))}
          </div>
        </div>

        <div className="h-[calc(100vh-500px)] overflow-y-auto mb-4 border rounded p-4">
          {messages.map(message => (
            <div
              key={message.id}
              className={`mb-4 ${message.role === 'user' ? 'text-right' : 'text-left'}`}
            >
              <div
                className={`inline-block p-3 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-800'
                }`}
              >
                {message.role === 'user' ? (
                  message.content
                ) : (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {message.content}
                  </ReactMarkdown>
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="text-gray-500 italic">Analyzing your time data...</div>
          )}
        </div>

        <form
        onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage(newMessage);
        }}
        className="flex flex-col gap-2"
        >
        <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="w-full border rounded px-3 py-2"
            placeholder="Ask about your time allocation..."
        />
        <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
            Send
        </button>
        </form>
      </div>
    </div>
  );
}

export default TimeAnalysisChat;