import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TopNav from '../common/TopNav';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Chat() {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [showPresetQuestions, setShowPresetQuestions] = useState(false);

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(() => {
    if (selectedConversation) {
      fetchMessages(selectedConversation.id);
    }
  }, [selectedConversation]);

  const presetQuestions = [
    'What are my strengths and how can I leverage them?',
    'What are my weaknesses and how can I improve on them?',
    'What brings me happiness and how can I incorporate more of it into my life?',
    'What causes me stress and how can I manage it effectively?',
    'In what moments, environments, and with what audiences do I perform at my best?',
    'What are my highlights and achievements that I am proud of?',
    'What brands do I associate with and why?',
    'How would I describe myself in a few sentences?',
    'What is my personal brand value and how do I communicate it?',
    'What is currently missing in my life that I desire?',
    'How do I define success for myself?',
    'What criteria do I use to measure my success?',
    'What am I truly passionate about?',
    'What do I love and how does it influence my life?',
    'What are my unique talents and how can I utilize them?',
    'What is my profession and how does it align with my values?',
    'What is the demand for my talent in the current market?',
    'What is my mission in life and how do I pursue it?',
    'What is the demand for my love and how do I express it?',
    'What benchmarks do I set for myself to gauge my progress?',
    'What are my life ROI investments and how do they pay off?',
    'What frictions do I encounter in my daily life and how can I resolve them?',
    'What opportunities are available to me and how can I seize them?'
  ];

  const fetchConversations = async () => {
    try {
      const response = await axios.get('/api/conversations');
      setConversations(response.data);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const fetchMessages = async (conversationId) => {
    try {
      const response = await axios.get(`/api/conversations/${conversationId}/messages`);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;

    const userMessage = { id: Date.now(), content: newMessage, is_user_message: true };
    setMessages([...messages, userMessage]);
    setNewMessage('');

    const loadingMessage = { id: Date.now() + 1, content: 'Loading...', is_user_message: false };
    setMessages(prevMessages => [...prevMessages, loadingMessage]);

    try {
      // Fetch historical data first
      const historicalResponse = await axios.get('/api/calendar/historical-data');
      const historicalData = historicalResponse.data;
  
      const response = await axios.post(`/api/conversations/${selectedConversation.id}/messages`, {
        content: newMessage,
        historicalData: historicalData
      });
      
      setMessages(prevMessages => [
        ...prevMessages.filter(msg => msg.id !== loadingMessage.id),
        response.data
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== loadingMessage.id));
    }
  };

  const handleNewConversation = async () => {
    try {
      const response = await axios.post('/api/conversations');
      setConversations([...conversations, response.data]);
      setSelectedConversation(response.data);
    } catch (error) {
      console.error('Error creating new conversation:', error);
    }
  };

  const handleRenameConversation = async () => {
    if (!newTitle.trim()) return;
    try {
      const response = await axios.put(`/api/conversations/${selectedConversation.id}`, {
        title: newTitle
      });
      setConversations(conversations.map(conv =>
        conv.id === selectedConversation.id ? { ...conv, title: newTitle } : conv
      ));
      setSelectedConversation({ ...selectedConversation, title: newTitle });
      setIsRenaming(false);
      setNewTitle('');
    } catch (error) {
      console.error('Error renaming conversation:', error);
    }
  };

  const handleDeleteConversation = async (conversationId) => {
    try {
      await axios.delete(`/api/conversations/${conversationId}`);
      setConversations(conversations.filter(conv => conv.id !== conversationId));
      if (selectedConversation && selectedConversation.id === conversationId) {
        setSelectedConversation(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const handlePresetQuestion = async (question) => {
    setNewMessage(question);
    setShowPresetQuestions(false);
  };

  return (
    <div>
      <TopNav />
      <div className="container mx-auto mt-8 p-4 flex flex-col md:flex-row">
        {/* Conversation list */}
        <div className="w-full md:w-1/4 pr-4 mb-4 md:mb-0">
          <button
            onClick={handleNewConversation}
            className="w-full mb-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            New Conversation
          </button>
          <div className="space-y-2">
            {conversations.map((conv) => (
              <div
                key={conv.id}
                className={`p-2 rounded cursor-pointer flex justify-between items-center ${selectedConversation && selectedConversation.id === conv.id
                  ? 'bg-blue-100'
                  : 'hover:bg-gray-100'
                  }`}
              >
                <div
                  className="flex-grow"
                  onClick={() => setSelectedConversation(conv)}
                >
                  {conv.title || 'Untitled Conversation'}
                </div>
                <button
                  onClick={() => handleDeleteConversation(conv.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Chat window */}
        <div className="w-full md:w-3/4 pl-0 md:pl-4">
          {selectedConversation ? (
            <>
              <div className="flex justify-between items-center mb-4">
                {isRenaming ? (
                  <div className="flex">
                    <input
                      type="text"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                      className="border rounded px-2 py-1 mr-2"
                      placeholder="New conversation title"
                    />
                    <button
                      onClick={handleRenameConversation}
                      className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <h2 className="text-xl font-semibold">{selectedConversation.title || 'Untitled Conversation'}</h2>
                )}
                <button
                  onClick={() => setIsRenaming(!isRenaming)}
                  className="bg-gray-200 text-gray-700 px-2 py-1 rounded hover:bg-gray-300"
                >
                  {isRenaming ? 'Cancel' : 'Rename'}
                </button>
              </div>
              <div className="bg-white rounded-lg shadow-md p-4 h-[calc(100vh-200px)] flex flex-col">
                <div className="flex-grow overflow-y-auto mb-4">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`mb-2 p-2 rounded max-w-[60%] ${message.is_user_message
                          ? 'ml-auto bg-blue-100'
                          : 'mr-auto bg-gray-100'
                        }`}
                    >
                      {message.content === 'Loading...' ? (
                        <div className="animate-pulse">Loading...</div>
                      ) : (
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                          {message.content}
                        </ReactMarkdown>
                      )}
                    </div>
                  ))}
                </div>
                <form onSubmit={handleSendMessage} className="flex flex-col">
                  <div className="flex mb-2">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      className="flex-grow mr-2 p-2 border rounded"
                      placeholder="Type your message..."
                    />
                    <button
                      type="submit"
                      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                    >
                      Send
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowPresetQuestions(!showPresetQuestions)}
                    className="bg-gray-200 text-gray-700 py-2 px-4 rounded hover:bg-gray-300"
                  >
                    {showPresetQuestions ? 'Hide Preset Questions' : 'Show Preset Questions'}
                  </button>
                  {showPresetQuestions && (
                    <div className="mt-2 max-h-40 overflow-y-auto">
                      {presetQuestions.map((question, index) => (
                        <button
                          key={index}
                          onClick={() => handlePresetQuestion(question)}
                          className="block w-full text-left p-2 hover:bg-gray-100 rounded"
                        >
                          {question}
                        </button>
                      ))}
                    </div>
                  )}
                </form>
              </div>
            </>
          ) : (
            <div className="text-center text-gray-500">
              Select a conversation or start a new one
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;